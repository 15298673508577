// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: true,
    current_env: 'preprod',
    needl_url: 'https://master.test.needl.co',
    needl_front_end_url: 'https://app.master.test.needl.co',
    needl_back_end_url: 'https://backend.master.test.needl.co',
    wabel_bo_url: 'https://bo.master.test.needl.co',
    needl_rabbit_url: "https://needlrabbit.master.test.needl.co",
    wabel_company_id: 34,
    zoho_crm_entity_url: "https://crm.zoho.com/crm/tab",
    google_maps_api_key : 'AIzaSyDg9JNdTfXzSDhxLQpDPtiPeWO8Hj2T-C0',
};
