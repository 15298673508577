import {WabelModel} from './wabel-model.abstract';
import {Event} from './event';
import {Tag} from './tag';

export class Summit extends WabelModel {
  constructor(obj?: any) {
    super(obj);
    if (obj) {
      this.certainEvent = obj.certainEvent ? new Event(obj.certainEvent) : null;
      this.startDate = obj.startDate ? new Date(obj.startDate) : null;
      this.endDate = obj.endDate ? new Date(obj.endDate) : null;
      this.rootCategories = obj.rootCategories && obj.rootCategories.items ? obj.rootCategories.items.map(category => new Tag(category)) : [];
      this.categories = obj.categories && obj.categories.items ? obj.categories.items.map(category => new Tag(category)) : [];
      this.tags = obj.tags && obj.tags.items ? obj.tags.items.map(tag => new Tag(tag)) : [];
    }
  }

  summitCode: string;
  name: string;
  startDate: Date;
  endDate: Date;
  eventCode: string;
  city: string;
  nodeUrl: string;
  bannerUrl: string;
  color: string;
  planningImageUrl: string;
  floorMapImageUrl: string;
  certainEvent: Event;
  categories: Tag[];
  rootCategories: Tag[];
  logoUrl: string;
  tags: Tag[];
  preregisterSentence: string;
  marketingPageUrl: string;

  // Field used only for uploading logo
  newSummitLogo: File;
  newBanner: File;

  get id(): string {
    return this.summitCode;
  }

  get formattedStartDate(): string {
    return this.startDate ? this.startDate.toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }) : null;
  }

  get formattedEndDate(): string {
    return this.endDate ? this.endDate.toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }) : null;
  }
}
